<template>
  <section class="coupon-setting" :v-loading="loading">
    <PageTitle
      :title="$t('resourceSettings.title')"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <ResourceConfigSettings :title="$t('resourceSettings.resourceDisplayName.title')" :formData="formData" @edit="modal.name = true">
      <BaseElFormItem :label="$t('resourceSettings.displayName.title')" prop="displayName">
        <div class="text-[#5E5E5E]">{{ formData.displayName }}</div>
      </BaseElFormItem>
    </ResourceConfigSettings>
    <ResourceConfigSettings :title="$t('resourceSettings.displayName.reservationSetting.title')" :formData="formData" @edit="modal.reservation = true">
      <BaseElFormItem :label="$t('resourceSettings.clientSkipSelect.title')" prop="clientSkipSelect">
        <div class="text-[#5E5E5E]">{{ displayData.clientSkipSelect }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('resourceSettings.assignRule.title')" prop="assignRule">
        <div class="text-[#5E5E5E]">{{ renderRuleName }}</div>
      </BaseElFormItem>
    </ResourceConfigSettings>

    <EditResourceConfigModal
      v-if="modal.reservation"
      :configData="configData"
      @close="modal.reservation = false"
      @refresh="getResourceConfig"
    />

    <ResourceDialog
      :title="$t('resourceSettings.resourceDisplayName.title')"
      :visible="modal.name"
      @close="modal.name = false"
      @confirm="confirm"
    >
      <BaseElForm ref="form" :model="formData">
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>{{ $t('resourceSettings.displayName.title')}}</p>
          <el-tooltip placement="right">
            <div slot="content">
              {{ $t('resourceSettings.displayName.tooltip')}}
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <BaseElFormItem prop="displayName">
          <BaseElInput v-model="formData.displayName" :maxlength="5" show-word-limit />
        </BaseElFormItem>
      </BaseElForm>
    </ResourceDialog>
    <!-- <ResourceDialog
      title="編輯設備預約設定"
      :visible="modal.reservation"
      @close="modal.reservation = false"
      @confirm="confirm"
    >
      <BaseElForm ref="form" :model="formData">
        <BaseElFormItem label="跳過選擇設備" prop="clientSkipSelect">
          <BaseElSwitch v-model="formData.clientSkipSelect" active-text="開" inactive-text="關" />
        </BaseElFormItem>

        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>同時空檔設備分配規則</p>
        </div>
        <BaseElFormItem prop="assignRule">
          <BaseElSelect v-model="formData.assignRule" class="form-select">
            <BaseElSelectOption
              v-for="item in assignRuleConfig"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <p class="text-[#636363] text-[14px]">
          預約數優先：依照前後14天內預約數，較低者優先，相同則隨機。<br>
          不指定預約數優先：依照前後14天內來自不指定的預約數，較低者優先，相同則隨機。<br>
          排序優先：依照服務人員的排序，較低者優先。
        </p>
      </BaseElForm>
    </ResourceDialog> -->
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import store from '@/store'
import { GetResourceConfig, UpdateResourceConfig } from '@/api/resource'
import { get } from 'lodash'
import { assignRuleConfig } from '@/config/resource'
import ResourceConfigSettings from './components/ResourceConfigSettings.vue'
import ResourceDialog from './components/ResourceDialog.vue'
import EditResourceConfigModal from './components/EditResourceConfigModal.vue'
import { i18n } from '@/plugins/i18n/i18n'
export default defineComponent({
  name: 'ResourceSettings',
  components: {
    ResourceConfigSettings,
    ResourceDialog,
    EditResourceConfigModal,
  },
  setup (props) {
    const loading = ref(false)
    const configData = ref({})
    const formData = reactive({
      displayName: '',
      assignRule: '',
      clientSkipSelect: false,
    })
    const modal = reactive({
      name: false,
      reservation: false,
    })
    const shopId = computed(() => store.getters.shop)

    const displayData = computed(() => {
      return {
        displayName: formData.displayName,
        assignRule: formData.assignRule,
        clientSkipSelect: formData.clientSkipSelect ? i18n.t('common.open.text') : i18n.t('common.close.text'),
      }
    })

    const confirm = async () => {
      await updateResourceConfig()
      modal.name = false
      modal.reservation = false
    }
    const renderRuleName = computed(() => {
      const option = assignRuleConfig.find((item) => item.value === formData.assignRule)
      if (option) {
        return i18n.t(option.label)
      }
      return ''
    })
    const getResourceConfig = async () => {
      loading.value = true
      const [res, err] = await GetResourceConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
      formData.displayName = get(res, 'pageText.RESOURCE') || i18n.t('resourceSettings.resourve.title')
      formData.assignRule = get(res, 'assignRule')
      formData.clientSkipSelect = get(res, 'clientSkipSelect')
    }

    const updateResourceConfig = async () => {
      loading.value = true
      const [, err] = await UpdateResourceConfig({
        shopId: shopId.value,
        pageText: { RESOURCE: formData.displayName },
        assignRule: formData.assingRule,
        clientSkipSelect: formData.clientSkipSelect,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(i18n.t('common.message.updateSuccess'))
      loading.value = false
    }

    onMounted(() => {
      getResourceConfig()
      console.log(assignRuleConfig)
    })
    return {
      loading,
      formData,
      modal,
      confirm,
      assignRuleConfig,
      renderRuleName,
      displayData,
      configData,
      getResourceConfig
    }
  },
})
</script>
<style lang="postcss" scoped>
.el-input, .el-select{
  @apply !w-full
}
::v-deep .form-select .el-input {
  @apply !w-full
}
</style>
